
export default {
  name: 'IconList',
  props: {
    color: {
      type: String,
      default: '#D74942',
    },
  },
}
